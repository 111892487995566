import { useContext } from 'react'

import ConfirmContext from './ConfirmContext'
import { ConfirmOptions } from './types'

const useConfirm = () => {
  const confirm =
    useContext<(options?: ConfirmOptions) => Promise<boolean>>(ConfirmContext)
  return confirm
}

export default useConfirm
